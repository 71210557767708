import {
  UserDataState,
  RootState,
  LoginCredential,
  GAuthCredential,
} from "../store.types";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";
import { PROFILE, TOKEN, AUTH_HEADER, ACCESS_LEVEL } from "../getter.names";
import {
  LOGIN,
  GET_PROFILE,
  UPDATE_PROFILE,
  LOGOUT,
  GET_TOKEN_FROM_LOCAL_STORE,
  GOOGLE_LOGIN,
} from "../action.names";
import axios from "axios";
import {
  LOGIN_URL,
  PROFILE_URL,
  LOGOUT_URL,
  SET_PASSWORD_URL,
  INVITATION_URL,
  GEN_RESET_PASSWORD_ENDPOINT,
  EDUCATION_INFO_UPDATE_URL,
  EMPLOYMENT_INFO_UPDATE_URL,
  CONTACT_INFO_UPDATE_URL,
  GOOGLE_AUTHENTICATION_ENDPOINT,
} from "../endpoints";
import { CLEAR_PROFILE_DATA } from "../mutation.names";
import {
  SET_PASSWORD,
  SEND_PASSWORD_RESET,
  PASSWORD_RESET_CONFIRM,
  EDUCATION_UPDATE,
  EDUCATION_INFO_DELETE,
  EMPLOYMENT_UPDATE,
  EMPLOYMENT_INFO_DELETE,
  CONTACT_UPDATE,
} from "../action.names";
import {
  SET_TOKEN,
  SET_TOKEN_ERROR,
  SET_PROFILE_DATA,
} from "../mutation.names";

const namespaced = true;

const state: UserDataState = {
  user: {
    profile: null,
    token: null,
  },
  error: false,
};

const getters: GetterTree<UserDataState, RootState> = {
  [PROFILE](state: UserDataState): any | null {
    return state.user.profile;
  },
  [ACCESS_LEVEL](state: UserDataState): number | null {
    return state.user.profile?.user?.role || null;
  },
  [TOKEN](state: UserDataState): string | null {
    return state.user.token;
  },
  [AUTH_HEADER](state): any {
    if (state.user.token == null) return {};
    return { Authorization: `Token ${state.user.token}` };
  },
};

const actions: ActionTree<UserDataState, RootState> = {
  [GOOGLE_LOGIN]({ commit, dispatch }, cred: GAuthCredential) {
    return new Promise((resolve, reject) => {
      axios
        .post(GOOGLE_AUTHENTICATION_ENDPOINT, cred)
        .then(({ data }) => {
          const token = data.key;
          commit(SET_TOKEN, token);
          dispatch(GET_PROFILE);
          resolve(data);
        })
        .catch((e) => {
          commit(SET_TOKEN_ERROR);
          reject(e);
        });
    });
  },
  async [LOGIN](
    { commit, dispatch },
    loginData: LoginCredential
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(LOGIN_URL + "/", loginData)
        .then(({ data }) => {
          commit(SET_TOKEN, data.key);
          dispatch(GET_PROFILE).finally(() => {
            resolve(data.key);
          });
        })
        .catch((e: any) => {
          commit(SET_TOKEN_ERROR);
          reject(e);
        });
    });
  },
  async [GET_PROFILE]({ commit, getters }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(PROFILE_URL + "/", {
          headers: {
            ...getters[AUTH_HEADER],
          },
        })
        .then(({ data }) => {
          commit(SET_PROFILE_DATA, data);
          resolve(data);
        })
        .catch((e: any) => {
          commit(SET_TOKEN_ERROR);
          reject(e);
        });
    });
  },
  async [UPDATE_PROFILE]({ commit, getters }, payload: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(PROFILE_URL + "/", payload, {
          headers: { ...getters[AUTH_HEADER] },
        })
        .then(({ data }) => {
          commit(SET_PROFILE_DATA, data);
          resolve(data);
        })
        .catch((e: any) => {
          console.log(e);
          reject(e);
        });
    });
  },
  async [EDUCATION_UPDATE](
    { commit, getters, dispatch },
    payload: any = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = EDUCATION_INFO_UPDATE_URL;
      const id = payload.id;
      const data = payload.data;
      if (id !== null) {
        method = axios.patch;
        url = `${EDUCATION_INFO_UPDATE_URL}${payload.id}/`;
      }
      method(url, data, {
        headers: {
          ...getters[AUTH_HEADER],
        },
      })
        .then(({ data }) => {
          dispatch(GET_PROFILE);
          resolve(data);
        })
        .catch((e: any) => {
          console.log(e);
          reject(e);
        });
    });
  },
  async [EDUCATION_INFO_DELETE]({ getters, dispatch }, infoId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${EDUCATION_INFO_UPDATE_URL}${infoId}`, {
          headers: {
            ...getters[AUTH_HEADER],
          },
        })
        .then(({ data }) => {
          dispatch(GET_PROFILE);
          resolve(data);
        })
        .catch((e: any) => {
          console.log(e);
          reject(e);
        });
    });
  },
  async [EMPLOYMENT_UPDATE](
    { commit, getters, dispatch },
    payload: any = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = axios.post;
      let url = EMPLOYMENT_INFO_UPDATE_URL;
      const id = payload.id;
      const data = payload.data;
      if (id !== null) {
        method = axios.patch;
        url = `${EMPLOYMENT_INFO_UPDATE_URL}${payload.id}/`;
      }
      method(url, data, {
        headers: {
          ...getters[AUTH_HEADER],
        },
      })
        .then(({ data }) => {
          dispatch(GET_PROFILE);
          resolve(data);
        })
        .catch((e: any) => {
          console.log(e);
          reject(e);
        });
    });
  },
  async [EMPLOYMENT_INFO_DELETE]({ getters, dispatch }, infoId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${EMPLOYMENT_INFO_UPDATE_URL}${infoId}`, {
          headers: {
            ...getters[AUTH_HEADER],
          },
        })
        .then(({ data }) => {
          dispatch(GET_PROFILE);
          resolve(data);
        })
        .catch((e: any) => {
          console.log(e);
          reject(e);
        });
    });
  },
  async [CONTACT_UPDATE](
    { commit, getters, dispatch },
    payload: any = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${CONTACT_INFO_UPDATE_URL}${payload.id}/`, payload, {
          headers: {
            ...getters[AUTH_HEADER],
          },
        })
        .then(({ data }) => {
          dispatch(GET_PROFILE);
          resolve(data);
        })
        .catch((e: any) => {
          console.log(e);
          reject(e);
        });
    });
  },
  async [LOGOUT]({ commit, getters }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          LOGOUT_URL + "/",
          {},
          {
            headers: {
              ...getters[AUTH_HEADER],
            },
          }
        )
        .then((data) => {
          commit(CLEAR_PROFILE_DATA);
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  },
  async [GET_TOKEN_FROM_LOCAL_STORE]({ commit, dispatch }): Promise<any> {
    return new Promise((resolve, reject) => {
      const localToken = localStorage.getItem("BEP_TOKEN");
      if (localToken != null) {
        commit(SET_TOKEN, localToken);
        dispatch(GET_PROFILE).finally(() => {
          resolve(true);
        });
      } else {
        reject();
      }
    });
  },
  async [SET_PASSWORD](ctx, data): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${INVITATION_URL}/${data.id}/set_password/`, {
          password: data.password,
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [SEND_PASSWORD_RESET](ctx, data): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${GEN_RESET_PASSWORD_ENDPOINT}`, {
          email: data,
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [PASSWORD_RESET_CONFIRM](ctx, data): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${GEN_RESET_PASSWORD_ENDPOINT}${data.token}/confirm/`, {
          password: data.password,
        })
        .then((data: any) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
};

const mutations: MutationTree<UserDataState> = {
  [SET_TOKEN](state, token) {
    state.user.token = token;
    state.error = false;
    localStorage.setItem("BEP_TOKEN", token);
  },
  [SET_TOKEN_ERROR](state) {
    state.user.token = null;
    state.user.profile = null;
    state.error = true;
    localStorage.removeItem("BEP_TOKEN");
  },
  [SET_PROFILE_DATA](state, data) {
    state.user.profile = data;
  },
  [CLEAR_PROFILE_DATA](state) {
    state.user.token = null;
    state.user.profile = null;
    state.error = false;
    localStorage.removeItem("BEP_TOKEN");
  },
};

const userDataStore: Module<UserDataState, RootState> = {
  namespaced,
  getters,
  actions,
  mutations,
  state,
};

export default userDataStore;
