import { RouteConfig } from "vue-router";

const courseRoutes: Array<RouteConfig> = [
  {
    path: "/search",
    name: "search",
    component: () => import("../views/CourseSearchlistPage.vue"),
    meta: {
      loginRequred: true,
      accessLevel: 0,
    },
  },
  {
    name: "public-courses",
    path: "/courses",
    component: () => import("../views/CourseList.vue"),
    meta: {
      loginRequred: true,
      accessLevel: 0,
    },
  },
  {
    name: "start-courses",
    path: "/courses/:courseSlug",
    component: () => import("../views/CourseStartPage.vue"),
    meta: {
      loginRequred: true,
      accessLevel: 0,
    },
  },
  {
    name: "my-courses",
    path: "/mycourses",
    component: () => import("../views/MyCourses.vue"),
    meta: {
      loginRequred: true,
      accessLevel: 0,
    },
  },
  {
    name: "continue",
    path: "/courses/:courseSlug/continue",
    component: () => import("../views/CourseLessonPage.vue"),
    meta: {
      loginRequred: true,
      accessLevel: 0,
    },
  },
  {
    name: "recap",
    path: "/courses/:courseSlug/recap/:id",
    component: () => import("../views/CourseLessonPage.vue"),
    meta: {
      loginRequred: true,
      accessLevel: 0,
    },
  },
  {
    name: "assignment-submission",
    path: "/courses/:courseSlug/assignments/:peerReviewId",
    component: () => import("../views/AssignmentDetailPage.vue"),
    meta: {
      loginRequred: true,
      accessLevel: 0,
    },
  },
  {
    name: "course-result",
    path: "/courses/:courseSlug/result",
    component: () => import("../views/CourseProgress.vue"),
    meta: {
      loginRequred: true,
      accessLevel: 0,
    },
  },
];

export default courseRoutes;
