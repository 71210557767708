import "./routerHooks";
import Vue from "vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import App from "./App.vue";
import router from "./router";

import store from "./store";
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import IdleVue from "idle-vue";

import KProgress from "k-progress";

import StarRating from "vue-star-rating";
import { namespaced } from "./store/utils";
import { NS_USER, NS_PUBLIC } from "./store/namespace.names";
import {
  GET_PROFILE,
  GET_TOKEN_FROM_LOCAL_STORE,
  LOGOUT,
} from "./store/action.names";
import { TOKEN, ACCESS_LEVEL, PROFILE } from "./store/getter.names";
import vueDebounce from "vue-debounce";
import { SET_COURSE_SEARCH_RESULTS } from "./store/mutation.names";

import VueYoutube from "vue-youtube";

import messages from "./translations.json";
import VueI18n from "vue-i18n";
import VueTour from "vue-tour";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import VueToast from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-bootstrap.css";

require("vue-tour/dist/vue-tour.css");

// Import Bootstrap and BootstrapVue CSS files (order is important)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueTour as any);

Vue.use(VueI18n);

const i18n = new VueI18n({ locale: "en", fallbackLocale: "en", messages });

Vue.use(vueDebounce);
Vue.use(VueYoutube);

Vue.use(require("vue-moment"));
Vue.use(VueVideoPlayer);
Vue.component("star-rating", StarRating);
Vue.component("k-progress", KProgress);

// toast
Vue.use(VueToast);

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // 3 seconds,
  startAtIdle: false,
  events: ["mousemove", "keydown", "mousedown", "touchstart"],
});

store.dispatch(namespaced(NS_USER, GET_TOKEN_FROM_LOCAL_STORE));

router.beforeEach(async (to, from, next) => {
  store.commit(namespaced(NS_PUBLIC, SET_COURSE_SEARCH_RESULTS), null);
  const loggedIn = store.getters[namespaced(NS_USER, TOKEN)] != null;

  // const hasEducation = contactInfo?.education?.length > 0;
  // const hasEmployment = contactInfo?.employment?.length > 0;

  if (to.meta?.loginRequired) {
    if (loggedIn) {
      const accessLevel = store.getters[namespaced(NS_USER, ACCESS_LEVEL)];
      const profile =
        store.getters[namespaced(NS_USER, PROFILE)] ||
        (await store.dispatch(namespaced(NS_USER, GET_PROFILE)));

      if (to.meta?.accessLevel <= accessLevel) {
        if (accessLevel > 0) {
          next();
          return;
        } else if (!profile.birthdate) {
          // next(`/profile/edit#personal?redirect=${to.path}`);

          router.push(`/profile/edit#personal?redirect=${to.path}`);
        } else if (profile.education.length > 0 === false) {
          // next(`/profile/edit#education?redirect=${to.path}`);
          router.push(`/profile/edit#education?redirect=${to.path}`);
        } else if (profile.employment.length > 0 === false) {
          router.push(`/profile/edit#employment?redirect=${to.path}`);
          //next(`/profile/edit#employment?redirect=${to.path}`);
        } else {
          next();
        }
      } else {
        next("/");
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
