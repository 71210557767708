
import { Component, Vue, Prop } from "vue-property-decorator";
import { humanizeDuration } from "../utils/format.utils";
import { API_HOST } from "../global";

@Component({
  name: "AllCourseCard",
})
export default class AllCourseCard extends Vue {
  @Prop() courses!: any;
  HOST = API_HOST;
  humanizeDuration = humanizeDuration;
}
