
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { PROFILE } from "../store/getter.names";
import { NS_USER } from "../store/namespace.names";
import { namespaced } from "../store/utils";
import moment from "moment";Vue.prototype.moment = moment
Vue.prototype.moment = moment

@Component({
  name: "Footer",
})
export default class Footer extends Vue {
  @Getter(namespaced(NS_USER, PROFILE)) profile!: any;
    $moment: any;

}
