
import { Vue, Prop, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { SITE_LANG } from "../../store/getter.names";
@Component({
  name: "Accordion"
})
export default class Accordion extends Vue {
@Prop() compData: any;
  open: number = 0;

  getTitle() {
    return this.compData.title_text;
  }

  get content() {
    return this.compData.content;
  }

  accordionIndex = 0;
  isActive: boolean = false;
  isAccordion: boolean = false;

}
