import { RouteConfig } from "vue-router";
import Page from "../views/cms/Page.vue";

const userRoutes: Array<RouteConfig> = [
  {
    path: "/postauthentication",
    name: "postauth",
    component: () => import("../views/PostAuthentication.vue"),
  },
  {
    name: "login",
    path: "/login",
    component: () => import("../views/LogInPage.vue"),
  },
  {
    name: "cms-page",
    path: "/pages/:cmsPath",
    component: Page,
  },
  {
    name: "SetPasswordPage",
    path: "/user/invites/:id",
    component: () => import("../views/ResetPasswordPage.vue"),
  },
  {
    name: "ResetPasswordEmail",
    path: "/user/reset/password",
    component: () => import("../views/ResetPasswordEmail.vue"),
  },
  {
    name: "ResetPassword",
    path: "/user/reset/password/confirm/:token",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/profile",
    name: "user-profile",
    component: () => import("../views/MyProfilePage.vue"),
    meta: {
      loginRequired: false,
    },
  },
  {
    path: "/profile/edit",
    name: "user-profile-edit",
    component: () => import("../views/NewUserProfileForm.vue"),
    meta: {
      loginRequired: false,
      access_level: 0,
    },
  },
  {
    path: "/intro",
    name: "intro",
    component: () => import("../views/AboutPage.vue"),
    meta: {
      authRequired: true,
      access_level: 0,
    },
  },
];

export default userRoutes;
