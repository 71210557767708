import Vue from "vue";
import Vuex from "vuex";
import userDataStore from "./modules/user";
import adminCourseData from "./modules/admin/courses";
import adminOrgModule from "./modules/admin/orgs";
import publicCourseData from "./modules/public/courses";
import forumCategory from "./modules/forum/forums";
import AdminAssignmentModule from "./modules/admin/assignments";
import mediaDataModule from "./modules/admin/media";
import AdminUserModule from "./modules/admin/users";
import GlobalStateModule from "./modules/global";
import { pageData } from './modules/pageData';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version: "1.0.0",
  },
  mutations: {},
  actions: {},
  modules: {
    GlobalStateModule,
    user: userDataStore,
    admin: {
      namespaced: true,
      modules: {
        courses: adminCourseData,
        assignments: AdminAssignmentModule,
        media: mediaDataModule,
        users: AdminUserModule,
        organizations: adminOrgModule,
      },
    },
    public: {
      namespaced: true,
      modules: {
        courses: publicCourseData,
        forums: forumCategory,
      },
    },
    pageData
  },
});
