
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { namespaced } from "../store/utils";
import { NS_USER, NS_PUBLIC } from "../store/namespace.names";
import { LOGOUT, SEARCH_COURSES } from "../store/action.names";
import { SET_COURSE_SEARCH_RESULTS } from "../store/mutation.names";
import { COURSE_SEARCH_RESULTS, PROFILE } from "../store/getter.names";
import SingleText from "./SingleText.vue";
import AllCourseCard from "./AllCourseCard.vue";
import SingleTitle from "./SingleTitle.vue";
import { API_HOST } from "@/global";
import { NS_ADMIN } from "../store/namespace.names";
import { FETCH_NOTIFICATIONS } from "../store/action.names";

@Component({
  name: "Navbar",
  components: {
    SingleTitle,
    AllCourseCard,
  },
})
export default class Navbar extends Vue {
  @Action(namespaced(NS_ADMIN, FETCH_NOTIFICATIONS)) fetchNotifications: any;
  accessLogs: any | null = null;
  HOST = API_HOST;
  showMenu: boolean = false;
  isDropped: boolean = false;
  searchShow: boolean = false;
  showBar: boolean = false;
  ismenuVisible: boolean = false;
  searchData = "";
  $router: any;
  isActive: boolean = true;
  @Action(namespaced(NS_USER, LOGOUT)) logout: any;
  @Action(namespaced(NS_PUBLIC, SEARCH_COURSES)) searchCourses: any;
  @Mutation(namespaced(NS_PUBLIC, SET_COURSE_SEARCH_RESULTS))
  setCourseSearchResults: any;

  @Getter(namespaced(NS_PUBLIC, COURSE_SEARCH_RESULTS)) searchResults: any;
  @Getter(namespaced(NS_USER, PROFILE)) profile: any;

  getAccessLog() {
    this.fetchNotifications()
      .then((data: any) => {
        this.accessLogs = data.results;
      })
      .catch((e: any) => {
        console.log(e);
      });
  }
  // @Watch("searchResults", { deep: true })
  // handleSearchResultChange(val: any, oldVal: any) {
  //   if (val != null) {
  //     this.$router.push({ name: "search" });
  //   }
  // }

  clearSearch() {
    this.setCourseSearchResults(null);
  }

  handleSearch(val: string, e: any) {
    if (val.length == 0) {
      this.clearSearch();
    } else {
      this.searchCourses({ search: val });
    }
  }

  addcls() {
    let d: any = document;
    let micon = d.querySelector("#mbmenu");
    micon?.classList.toggle("icon-active");
    this.ismenuVisible = !this.ismenuVisible;
  }
  showSearch() {
    this.searchShow = !this.searchShow;
  }
  dropNotif() {
    this.showBar = !this.showBar;
  }
  dropDown() {
    this.isDropped = !this.isDropped;
  }
  // listClass() {
  //   if (this.showMenu) {
  //     return "show";
  //   }
  //   return "";
  // }
  notificationToggle() {
    this.showMenu = !this.showMenu;
  }
  toggle() {
    this.showMenu = !this.showMenu;
  }

  isShow() {
    this.showMenu = true;
  }
  itemFocus() {
    var self = this;
    Vue.nextTick(function () {
      if (!self.showMenu) {
        self.showMenu = true;
      }
    });
  }
  itemBlur() {
    var self = this;
    Vue.nextTick(function () {
      if (self.showMenu) {
        self.showMenu = false;
      }
    });
  }
  public handleScroll() {
    let scrollpos;
    const d: any = document;
    const nav = d.querySelector(".navbar-wrap");
    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY || window.pageYOffset;
      if (scrollpos > 5) {
        nav?.classList.add("navbar-fixed");
      } else {
        nav?.classList.remove("navbar-fixed");
      }
    });
  }
  public mounted() {
    this.getAccessLog();
    window.addEventListener("scroll", this.handleScroll);
  }

  public created() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.$route.path == "/login") {
      this.isActive = false;
    }
    if (this.$route.path == "/reset-password") {
      this.isActive = false;
    }
  }

  switchLocale() {
    if (this.$i18n.locale === "en") {
      this.$i18n.locale = "bn";
    } else {
      this.$i18n.locale = "en";
    }
  }

  public destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  // @Watch("queryParams", { deep: true })
  // handleQueryParamChange(val: any, oldVal: any) {
  //   this.getAccessLog(val);
  // }
  // nextData(page: number) {
  //   this.queryParams.offset = this.queryParams.limit * (page - 1);
  // }
  // getAccessLog(params: any | null = null) {
  //   if (params == null) {
  //     params = this.queryParams;
  //   }
  //   this.accessLog = null;
  //   this.fetchNotifications(params)
  //     .then((data: any) => {
  //       this.accessLog = data;
  //     })
  //     .catch((e: any) => {
  //       console.log(e);
  //     });
  // }
}
