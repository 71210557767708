export const GOOGLE_CLIENT_ID: string = process.env.VUE_APP_GCLIENT_ID;
// nbuild

export const HOST: string = process.env.VUE_APP_DEVHOST
  ? process.env.VUE_APP_DEVHOST
  : process.env.NODE_ENV === "development"
  ? "http://localhost:8080"
  : "";

export const API_HOST: string = process.env.VUE_APP_API_HOST
  ? process.env.VUE_APP_API_HOST
  : "http://localhost:8000";

const G_URL: string = "https://accounts.google.com/o/oauth2/v2/auth";
const URL_PARTS = {
  client_id:  GOOGLE_CLIENT_ID,
  redirect_uri: `${HOST}/postauthentication`,
  response_type: "token",
  scope:
    "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
  prompt: "select_account"
};
const paramString = Object.keys(URL_PARTS)
  .map((key: string) => {
    return `${key}=${(URL_PARTS as any)[key] as string}`;
  })
  .join("&");
export const LOGIN_URL: string = encodeURI(`${G_URL}?${paramString}`);
