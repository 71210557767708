import vue from "vue";
import Axios from "axios";
import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import {
  RootState,
  PublicCourseDataState,
  PublicCourseState,
} from "../../store.types";
import { namespaced } from "../../utils";
import { NS_USER, NS_PUBLIC } from "../../namespace.names";
import { ADD_STATUS, SEARCH_COURSES, RE_ENROLL, FETCH_ENROLLMENT_PEER_REVIEW_ASSIGNMENT, FETCH_ENROLLMENT_PEER_REVIEW_ASSIGNMENTS, SUBMIT_PEER_REVIEW_ASSIGNMENT } from "../../action.names";
import { COURSE_SEARCH_RESULTS } from "../../getter.names";
import { SET_COURSE_SEARCH_RESULTS } from "../../mutation.names";
import {
  COURSES_ENDPOINT,
  PUBLIC_EXAM,
  PUBLIC_LESSON,
  ENROLLMENT_ENDPOINT,
} from "@/store/endpoints";
import {
  AUTH_HEADER,
  COURSES,
  GET_COURSE_BY_SLUG,
  ENROLLMENTS,
  ENROLLMENT,
  COURSE_MODULES,
  COMPLETED_ENROLLMENTS
} from "../../getter.names";
import {
  FETCH_COURSES,
  FETCH_COURSE,
  FETCH_ENROLLMENTS,
  FETCH_ENROLLMENT,
  FETCH_EXAM_SHEET,
  VALIDATE_EXAM_ANSWER,
  FETCH_LESSON,
  FETCH_LESSON_CONTENTS,
  FETCH_COURSES_MODULES,
  FETCH_COURSE_NEXT_CONTENT,
  FETCH_COMPLETED_ENROLLMENTS
} from "../../action.names";
import {
  SET_COURSES,
  SET_COURSE_BY_SLUG,
  SET_ENROLLMENTS,
  SET_ENROLLMENT,
  SET_COURSE_MODULES,
  SET_COMPLETED_ENROLLMENTS
} from "../../mutation.names";

const state: PublicCourseDataState = {
  courses: {
    data: [],
    count: null,
    next: null,
    previous: null,
  },
  searchResults: null,
  fullCourses: {},
  enrollments: {
    data: [],
    count: null,
    next: null,
    previous: null,
  },
  fullEnrollments: {},
  courseModules: {
    data: [],
    count: null,
    next: null,
    previous: null,
  },
  completedEnrollments: {}
};

const getters: GetterTree<PublicCourseDataState, RootState> = {
  [COURSES](state: PublicCourseDataState): APITypes.PublicCourse[] {
    return state.courses.data;
  },
  [GET_COURSE_BY_SLUG]: (state) => (slug: string) => state.fullCourses[slug],

  [ENROLLMENTS](state: PublicCourseDataState): APITypes.FlatPublicEnrollment[] {
    return state.enrollments.data;
  },
  [COMPLETED_ENROLLMENTS](state: PublicCourseDataState): APITypes.FlatPublicEnrollment[] {
    return state.completedEnrollments;
  },
  [ENROLLMENT]: (state) => (id: number) => state.fullEnrollments[id],
  [COURSE_MODULES](state: PublicCourseDataState): Array<any> {
    return state.courseModules.data;
  },
  [COURSE_SEARCH_RESULTS](
    state: PublicCourseDataState
  ): PublicCourseState | null {
    return state.searchResults;
  },
};

const actions: ActionTree<PublicCourseDataState, RootState> = {
  [FETCH_COURSES]({ commit, state, rootGetters }, params: any = {}) {
    // const url = state.courses.data.length == 0 && state.courses.next == null ? COURSES_ENDPOINT : state.courses.next;
    const url = COURSES_ENDPOINT + "/";
    if (url != null) {
      Axios.get(url, {
        params,
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          commit(SET_COURSES, { data });
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  },
  async [SEARCH_COURSES](
    { commit, state, rootGetters },
    params: any = {}
  ): Promise<any> {
    // const url = state.courses.data.length == 0 && state.courses.next == null ? COURSES_ENDPOINT : state.courses.next;
    return new Promise((resolve, reject) => {
      if (params.search == undefined) {
        resolve(null);
      }
      const url = COURSES_ENDPOINT + "/";
      if (url != null) {
        Axios.get(url, {
          params,
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
        })
          .then(({ data }) => {
            if (data.count == 0) {
              data = null;
            }
            commit(SET_COURSE_SEARCH_RESULTS, data);
            resolve(data);
          })
          .catch((e: any) => {
            reject(e);
          });
      }
    });
  },
  async [FETCH_COURSE](
    { commit, rootGetters, getters },
    { slug, refresh }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const cache = getters[GET_COURSE_BY_SLUG](slug);
      if (!refresh && cache !== undefined) {
        resolve(cache);
      } else {
        Axios.get(`${COURSES_ENDPOINT}/${slug}/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
        })
          .then(({ data }) => {
            commit(SET_COURSE_BY_SLUG, data);
            resolve(data);
          })
          .catch((e: any) => {
            reject(e);
          });
      }
    });
  },
  [FETCH_ENROLLMENTS]({ commit, state, rootGetters }, params: any = {}) {
    const url = ENROLLMENT_ENDPOINT + "/";
    if (url != null) {
      Axios.get(url, {
        params,
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          commit(SET_ENROLLMENTS, { data });
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  },
  [FETCH_COMPLETED_ENROLLMENTS]({ commit, state, rootGetters }) {
    const url = ENROLLMENT_ENDPOINT + "/?status=completed";
    if (url != null) {
      Axios.get(url, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          commit(SET_COMPLETED_ENROLLMENTS, { data });
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  },
  [FETCH_ENROLLMENT](
    { commit, rootGetters, getters },
    { enrollmentID, refresh }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const cache = getters[ENROLLMENT](enrollmentID);
      if (!refresh && cache !== undefined) {
        resolve(cache);
      } else {
        Axios.get(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/`, {
          headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
        })
          .then(({ data }) => {
            commit(SET_ENROLLMENT, data);
            resolve(data);
          })
          .catch((e: any) => {
            reject(e);
          });
      }
    });
  },
  [RE_ENROLL](
    { commit, rootGetters, getters },
    enrollmentID: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/re_enroll/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          commit(SET_ENROLLMENT, data);
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FETCH_COURSES_MODULES]({ commit, rootGetters }, slug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${COURSES_ENDPOINT}/${slug}/modules/`;
      Axios.get(url, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          commit(SET_COURSE_MODULES, { data });
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  [FETCH_EXAM_SHEET]({ rootGetters }, { payload, examID }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${PUBLIC_EXAM}/${examID}/get_question_sheet/`, payload, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  [VALIDATE_EXAM_ANSWER]({ rootGetters }, { payload, examID }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${PUBLIC_EXAM}/${examID}/validate/`, payload, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  [FETCH_LESSON]({ rootGetters }, lessonID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${PUBLIC_LESSON}/${lessonID}/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  [FETCH_LESSON_CONTENTS]({ rootGetters }, lessonID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${PUBLIC_LESSON}/${lessonID}/contents/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  [FETCH_COURSE_NEXT_CONTENT]({ rootGetters }, { slug }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${COURSES_ENDPOINT}/${slug}/next_content/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [ADD_STATUS](
    { rootGetters, dispatch },
    { enrollmentID, status }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/add_status/`, status, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          dispatch(FETCH_ENROLLMENT, {
            enrollmentID,
            refresh: true,
          });
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [FETCH_ENROLLMENT_PEER_REVIEW_ASSIGNMENT]({ rootGetters }, { enrollmentID, peerReviewID }): Promise<any> { 
    return new Promise((resolve, reject) => {
      Axios.get(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/assignments/${peerReviewID}/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [FETCH_ENROLLMENT_PEER_REVIEW_ASSIGNMENTS]({ rootGetters }, enrollmentID): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/assignments/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async [SUBMIT_PEER_REVIEW_ASSIGNMENT]({ rootGetters }, { enrollmentID, peerReviewID, payload }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ENROLLMENT_ENDPOINT}/${enrollmentID}/assignments/${peerReviewID}/submit/`, payload, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(() => {
          resolve(true);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
};

const mutations: MutationTree<PublicCourseDataState> = {
  [SET_COURSES](state: any, payload) {
    state.courses.data = payload.data.results;
    state.courses.count = payload.data.count;
    state.courses.next = payload.data.next;
    state.courses.previous = payload.data.previous;
  },
  [SET_COURSE_BY_SLUG](state, payload) {
    vue.set(state.fullCourses, payload.slug, payload);
  },
  [SET_ENROLLMENTS](state: any, payload) {
    state.enrollments.data = payload.data.results;
    state.enrollments.count = payload.data.count;
    state.enrollments.next = payload.data.next;
    state.enrollments.previous = payload.data.previous;
  },
  [SET_ENROLLMENT](state, payload) {
    vue.set(state.fullEnrollments, payload.id, payload);
  },
  [SET_COURSE_MODULES](state: any, payload) {
    state.courseModules.data = payload.data.results;
    state.courseModules.count = payload.data.count;
    state.courseModules.next = payload.data.next;
    state.courseModules.previous = payload.data.previous;
  },
  [SET_COURSE_SEARCH_RESULTS](state: any, payload) {
    state.searchResults = payload;
  },
  [SET_COMPLETED_ENROLLMENTS](state: any, payload) {
    state.completedEnrollments = payload.data.results;
  },
};

const publicCourseData: Module<PublicCourseDataState, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state,
};

export default publicCourseData;
