import { GetterTree, ActionTree, MutationTree, Module } from "vuex";
import { RootState, GlobalState } from "../store.types";
// import { SITE_LANG } from "../getter.names";
// import { SET_SITE_LANG, RETRIEVE_SITE_LANG } from "../mutation.names";
import {
  FETCH_CERTIFICATE_BY_ID, FETCH_GENERATE_CERTIFICATE, GET_CERTIFICATE_DETAIL } from "../action.names";
import axios from "axios";
import { CERTIFICATE_ENDPOINT, GEN_CERTIFICATE_ENDPOINT } from "../endpoints";

const namespaced: boolean = false;

// const LANGS = ["en", "bn"];

export const state: GlobalState = {
  lang: "en",
  generalSettings: null,
};

const getters: GetterTree<GlobalState, RootState> = {
  // [SITE_LANG](state): string {
  //   return state.lang;
  // },
};

const actions: ActionTree<GlobalState, RootState> = {
  async [FETCH_CERTIFICATE_BY_ID]({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${CERTIFICATE_ENDPOINT}?course=${payload.course}&user=${payload.user}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_GENERATE_CERTIFICATE]({ commit }, id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GEN_CERTIFICATE_ENDPOINT}${id}/generate_certificate/`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_CERTIFICATE_DETAIL]({ commit }, id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GEN_CERTIFICATE_ENDPOINT}${id}/`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }
};

const mutations: MutationTree<GlobalState> = {
  // [SET_SITE_LANG](state, payload) {
  //   const lang = LANGS.indexOf(payload) > -1 ? payload : "en";
  //   state.lang = lang;
  //   window.localStorage.setItem("lang", lang);
  // },
  // [RETRIEVE_SITE_LANG](state) {
  //   const rlang = window.localStorage.getItem("lang");
  //   if (rlang != null) {
  //     state.lang = rlang;
  //   }
  // },
};

export const GlobalStateModule: Module<GlobalState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default GlobalStateModule;
