import { RouteConfig } from "vue-router";

export const adminRoutes: Array<RouteConfig> = [
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/Admin/Admin.vue"),
    meta: {
      loginRequred: true,
      accessLevel: 1,
    },
    children: [
      {
        name: "admin-dashboard",
        path: "",
        component: () => import("../views/Admin/Dashboard.vue"),
        meta: {
          loginRequred: true,
          accessLevel: 1,
        },
      },
      {
        name: "admin-courses",
        path: "courses",
        component: () => import("../views/Admin/CourseOverview.vue"),
        meta: {
          loginRequred: true,
          accessLevel: 1,
        },
        children: [
          {
            name: "admin-courses",
            path: "",
            component: () => import("../views/Admin/Course/CourseListView.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
          {
            name: "course-create",
            path: "create",
            component: () =>
              import("../views/Admin/Course/CourseCreateView.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
          {
            name: "admin-course-detail",
            path: ":slug",
            component: () =>
              import("../views/Admin/Course/CourseDetailView.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
          {
            name: "admin-course-leaderboard",
            path: ":slug/leaderboard",
            component: () => import("../views/LeaderBoardPage.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
          {
            name: "admin-course-participents",
            path: ":slug/participents",
            component: () => import("../views/Admin/Course/CourseParticipents.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
          {
            name: "admin-course-games",
            path: ":slug/games",
            component: () => import("../views/Admin/Course/Game/GameIndex.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
          {
            name: "admin-course-game-detail",
            path: ":slug/games/:id",
            component: () => import("../views/Admin/Course/Game/GameDetailView.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
          {
            name: "admin-course-demo",
            path: ":slug/demo",
            component: () =>
              import("../views/Admin/Course/CourseViewDemo.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
          {
            name: "admin-module-demo",
            path: ":slug/demo/:moduleId/contents",
            component: () =>
              import("../views/Admin/Course/ModuleLessonPage.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
          {
            name: "admin-course-demo-content",
            path: ":slug/demo/:moduleId/:contentId",
            component: () =>
              import("../views/Admin/Course/CourseLessonPageDemo.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 1,
            },
          },
        ],
      },
      {
        name: "admin-assignments",
        path: "assignments",
        component: () => import("../views/Admin/AssignPeoplePage.vue"),
        meta: {
          loginRequred: true,
          accessLevel: 1,
        },
      },
      {
        name: "admin-access-log",
        path: "access-log",
        component: () => import("../views/Admin/AccessLogPage.vue"),
        meta: {
          loginRequred: true,
          accessLevel: 2,
        },
      },
      {
        name: "admin-user",
        path: "users/:userID",
        component: () => import("../views/Admin/User/AdminProfile.vue"),
        meta: {
          loginRequred: true,
          accessLevel: 2,
        },
      },
      {
        name: "admin-users",
        path: "users",
        component: () => import("../views/Admin/UserPage.vue"),
        meta: {
          loginRequred: true,
          accessLevel: 2,
        },
      },
      {
        name: "admin-organizations",
        path: "organizations",
        component: () => import("../views/Admin/OrganizationOverview.vue"),
        meta: {
          loginRequred: true,
          accessLevel: 2,
        },
        children: [
          {
            name: "admin-organizations",
            path: "",
            component: () => import("@/components/Admin/Organization/OrganizationList.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 2,
            },
          },
          {
            name: "admin-organization-details",
            path: ":slug",
            component: () => import("@/components/Admin/Organization/OrganizationDetails.vue"),
            meta: {
              loginRequred: true,
              accessLevel: 2,
            }
          }
        ]
      },
    ],
  },
];
