import { FETCH_DASHBOARD_STATS, FETCH_USER } from './../../action.names';
import { AdminUserState, RootState } from "../../store.types";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";
import { GET_USERS, UPDATE_USER, FETCH_NOTIFICATIONS, INVITE_PEOPLE, DELETE_USER, DISABLE_ENABLE_USER, SEND_EMAIL_USER, USER_SEARCH, COURSE_PARTICIPENTS, SEARCH_PARTICIPENTS, FETCH_OVERALL_STATS, FETCH_COURSE_STATS, FETCH_BY_COURSE_STATS, SEND_EMAIL_ALL_PARTICIPENTS } from "../../action.names";
import { ADMIN_USERS_URL, NOTIFICATIONS_URL, ADMIN_INVITE_PEOPLE_URL, INVITATION_URL, ADMIN_API, ADMIN_ASSIGNEES_URL, ADMIN_PARTICIPENTS_URL, OVERALL_STATS_ENDPOINT, COURSE_STATS_ENDPOINT, ADMIN_COURSES_URL, USER_ENABLE_DISABLE_URL, DASHBOARD_COURSES_ENDPOINT } from "@/store/endpoints";
import { buildParams } from "@/store/utils";
import { namespaced } from "../../utils";
import Axios from "axios";
import { NS_USER } from "../../namespace.names";
import { AUTH_HEADER } from "../../getter.names";
const state: AdminUserState = {};
const getters: GetterTree<AdminUserState, RootState> = {};
const actions: ActionTree<AdminUserState, RootState> = {
  async [GET_USERS]({ rootGetters }, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_USERS_URL}/${buildParams(params)}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [USER_SEARCH]({ rootGetters }, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_USERS_URL}/?search=${params}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [UPDATE_USER]({ rootGetters }, params): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.patch(`${ADMIN_USERS_URL}/${params.id}/`, params.payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DELETE_USER]({ rootGetters }, userId): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.delete(`${ADMIN_API}/user-remove/${userId}/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DISABLE_ENABLE_USER]({ rootGetters }, userId): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${USER_ENABLE_DISABLE_URL}/${userId}/`, {}, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [SEND_EMAIL_USER]({ rootGetters }, data): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ADMIN_API}/send-email/`, data, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [SEND_EMAIL_ALL_PARTICIPENTS]({ rootGetters }, data): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ADMIN_COURSES_URL}/${data.slug}/send_bulk_email/`, {message: data.message}, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_NOTIFICATIONS]({ rootGetters }, params = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${NOTIFICATIONS_URL}/${buildParams(params)}`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      }).then(({ data }) => {
        resolve(data);
      }).catch((e: any) => {
        reject(e);
      });
    });
  },
  async [INVITE_PEOPLE]({ rootGetters }, params = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${INVITATION_URL}/invite/`, params, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      }).then(({ data }) => {
        resolve(data);
      }).catch((e: any) => {
        reject(e);
      });
    });
  },
  async [COURSE_PARTICIPENTS]({ rootGetters }, payload: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_PARTICIPENTS_URL}/${payload.course_id}/${buildParams(payload.params)}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [SEARCH_PARTICIPENTS]({ rootGetters }, payload: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_PARTICIPENTS_URL}/${payload.course_id}/?search=${payload.params}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_OVERALL_STATS]({ rootGetters }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${OVERALL_STATS_ENDPOINT}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_COURSE_STATS]({ rootGetters }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${COURSE_STATS_ENDPOINT}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_DASHBOARD_STATS]({ rootGetters }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${DASHBOARD_COURSES_ENDPOINT}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_BY_COURSE_STATS]({ rootGetters }, courseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${COURSE_STATS_ENDPOINT}?course=${courseId}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_USER]({ rootGetters }, userId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_USERS_URL}/${userId}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
};
const mutations: MutationTree<AdminUserState> = {};

export const AdminUserModule: Module<AdminUserState, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state,
};

export default AdminUserModule;
