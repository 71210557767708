import * as dayjs from "dayjs";

export function standardDate(dateString: string | Date | dayjs.Dayjs) {
  return (dayjs as any)(dateString).format("DD MMM YYYY");
}
export function standardDateTime(dateString: string | Date | dayjs.Dayjs) {
  return (dayjs as any)(dateString).format("DD MMM YYYY hh:mm A");
}

export function apiDate(dateString: string | Date | dayjs.Dayjs) {
  return (dayjs as any)(dateString).format("YYYY-MM-DD");
}


export function formatFloat(value: number | string, precision: number = 2): string {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  return value.toFixed(precision);
}

export function humanizeDuration(duration: string): string {
  const daysplit = duration.split(" ");
  let days: number = 0;
  let parts: Array<number> = [];
  if (daysplit.length == 2) {
    days = parseInt(daysplit[0]);
    parts = daysplit[1].split(":").map((p: string) => parseInt(p));
  } else {
    parts = daysplit[0].split(":").map((p: string) => parseInt(p));
  }
  return [
    days != 0 ? days + ` day${days > 1 ? "s" : ""}` : "",
    parts[0] != 0 ? parts[0] + ` hour${parts[0] > 1 ? "s" : ""}` : "",
    parts[1] != 0 ? parts[1] + ` minute${parts[1] > 1 ? "s" : ""}` : "",
    parts[2] != 0 ? parts[2] + ` second${parts[2] > 1 ? "s" : ""}` : "",
  ].join(" ");
}


export function secondsToHourMinuteSeconds(totalSeconds: number): any {
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;
  const x = `${hours}:${minutes}:${seconds}`;
  return x;
}