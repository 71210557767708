import { AdminOrgState, Organization, RootState } from "../../store.types";
import { ORGANIZATIONS } from "../../getter.names";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";
import {
    GET_ORGS, CREATE_OR_UPDATE_ORGS, GET_ORG_BY_SLUG, DELETE_ORG_MEMBER,
    ADD_ORG_MEMBERS,
} from "../../action.names";
import { SET_ORGS, SET_ORGS_ERROR } from "@/store/mutation.names";
import { ADMIN_ORG_URL } from "@/store/endpoints";
import { buildParams } from "@/store/utils";
import { namespaced } from "../../utils";
import Axios from "axios";
import { NS_USER } from "../../namespace.names";
import { AUTH_HEADER } from "../../getter.names";


const state: AdminOrgState = {
    organizations: [],
    error: false
};
const getters: GetterTree<AdminOrgState, RootState> = {
    [ORGANIZATIONS](state): Organization[] {
        return state.organizations;
    }
};
const actions: ActionTree<AdminOrgState, RootState> = {
    async [GET_ORGS]({ rootGetters, commit }, searchTerm: string): Promise<any> {
        return new Promise(() => {
            let url: any = null;
            if (searchTerm != null) {
                url = `${ADMIN_ORG_URL}/?search=${searchTerm}`;
            } else {
                url = ADMIN_ORG_URL
            }

            Axios
                .get(url, {
                    headers: {
                        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
                    },
                })
                .then(({ data }) => {
                    commit(SET_ORGS, data);
                })
                .catch((e: any) => {
                    commit(SET_ORGS_ERROR);
                });
        });
    },
    async [CREATE_OR_UPDATE_ORGS]({ rootGetters, dispatch }, postData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let url: any = null;
            let method = Axios.post
            if (postData.slug != null) {
                url = `${ADMIN_ORG_URL}/${postData.slug}/`;
                method = Axios.patch
            } else {
                url = `${ADMIN_ORG_URL}/`;
            }
            method(url, postData.data, {
                headers: {
                    ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
                },
            })
                .then(({ data }) => {
                    dispatch(GET_ORGS);
                    resolve(data);
                })
                .catch((e: any) => {
                    reject(e)
                    // commit(SET_ORGS_ERROR);
                });
        });
    },
    async [GET_ORG_BY_SLUG]({ rootGetters }, slug: string): Promise<any> {
        return new Promise((resolve, reject) => {
            Axios.get(`${ADMIN_ORG_URL}/${slug}/`, {
                headers: {
                    ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
                },
            })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((e: any) => {
                    reject(e)
                });
        });
    },
    async [DELETE_ORG_MEMBER]({ rootGetters }, postData): Promise<any> {
        return new Promise((resolve, reject) => {
            const data = {
                "member_id": postData.member_id
            }
            Axios
                .post(
                    `${ADMIN_ORG_URL}/${postData.slug}/delete_member_from_ogranization/`, data, {
                    headers: {
                        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
                    },
                }
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async [ADD_ORG_MEMBERS]({ rootGetters }, postData): Promise<any> {
        return new Promise((resolve, reject) => {
            const data = {
                "members_id": postData.members_id
            }
            const slug = postData.slug
            Axios
                .post(
                    `${ADMIN_ORG_URL}/${slug}/add_members_in_organization/`, data, {
                    headers: {
                        ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
                    },
                }
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    //   async [DELETE_BATCH]({ rootState }, payload): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //       const opts = generateAuthHeader(rootState.AuthModule.token);
    //       axios
    //         .delete(
    //           `${BATCH_USER_DELETE_URL}${payload.batch_id}/`, opts
    //         )
    //         .then((response) => {
    //           resolve(response);
    //         })
    //         .catch((error) => {
    //           reject(error);
    //         });
    //     });
    //   },
};
const mutations: MutationTree<AdminOrgState> = {
    [SET_ORGS](state, payload) {
        state.organizations = payload;
        state.error = false;
    },
    [SET_ORGS_ERROR](state) {
        state.error = true;
    }
};

export const adminOrgModule: Module<AdminOrgState, RootState> = {
    namespaced: false,
    getters,
    actions,
    mutations,
    state,
};

export default adminOrgModule;
