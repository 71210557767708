export const namespaced = (namespace: string, asset: string) =>
  `${namespace}/${asset}`;

export const buildParams = (params: { [key: string]: any }): string => {
  const keys = Object.keys(params);
  if (keys.length == 0) return "";
  return (
    "?" +
    keys
      .map((key: string) => {
        return `${key}=${params[key]}`;
      })
      .join("&")
  );
};

export const isVideo = (type: string | null) => type && (type.toLowerCase() === "youtu" || type.toLowerCase() === "video");
export const isPDF = (type: string | null) => type && type.toLowerCase() === "pdf";
export const isImage = (type: string | null) => type && type.toLowerCase() === "img";
export const isText = (type: string | null) => type && type.toLowerCase() === "text";

export const calculateCourseMeta = (data: any) => {
  let coursemeta = {
    totalExam: 0,
    totalLesson: 0,
    countVideo: 0,
    countPDF: 0,
    countImage: 0,
    countText: 0,
  };
  function countType(obj: any, lesson_type: any) {
    Object.keys(lesson_type).forEach((type: string) => {
      if (type === "YOUTU" || type === "VIDEO")
        obj.countVideo += lesson_type[type];
      else if (type === "PDF") obj.countPDF += lesson_type[type];
      else if (type === "IMG") obj.countImage += lesson_type[type];
      else if (type === "TEXT") obj.countText += lesson_type[type];
    });
  }
  data.modules.forEach((module_obj: any) => {
    let { exam, lesson } = module_obj.contents.reduce(
      (state: any, data: any) => {
        if (data.c_type == "lesson") {
          countType(coursemeta, data.lesson_type);
          state.lesson += 1;
        }
        if (data.c_type == "exam") {
          state.exam += 1;
        }
        return state;
      },
      { exam: 0, lesson: 0 }
    );
    coursemeta.totalExam += exam;
    coursemeta.totalLesson += lesson;
  });
  return coursemeta;
}


export const rearangeScore = (enrollment: any) =>  {
  let temp;
  temp = enrollment.progress.map((content: any) => {
    if (
      content.content_object &&
      content.content_object.exam_sheet &&
      content.content_object.exam_sheet.exam &&
      content.content_object.passed == true
    ) {
      return {
        id: content.content_object.exam_sheet.exam.id,
        score: content.content_object.score,
        passed: content.content_object.passed,
      }
    }
  });
  let exems = temp.filter((each: any) => each);
  temp = null;
  temp = enrollment.modules.map((contents: any) => {
    // console.log(contents)
    let eachModule = contents.contents.map((content: any) => {
      if (content.c_type == "exam") {
        let exam = exems.filter((exam: any) => exam.id == content.id);
        if (exam.length) {
          return {
            id: content.id,
            name: content.name,
            weight: content.weight,
            exclude_from_calculation: content.exclude_from_calculation,
            score: exam[0].score,
            passed: exam[0].passed,
          };
        }
      }
    });
    eachModule = eachModule.filter((each: any) => each);
    return eachModule;
  });
  return [].concat(...temp);
}