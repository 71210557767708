import { Params } from "../store/store.types";
export function buildParams(params: Params): string {
  // console.log(params);
  if (Object.keys(params).length === 0) {
    return "";
  }
  return (
    "?" +
    Object.keys(params)
      .map((key) => {
        return `${key}=${encodeURI(params[key])}`;
      })
      .join("&")
  );
}

export function convertModelToFormData(
  model: any,
  form: FormData | null = null,
  namespace: string = ""
): FormData {
  const formData = form || new FormData();
  for (const propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || !model[propertyName]) {
      continue;
    }
    const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date) {
      formData.append(formKey, model[propertyName].toISOString());
    } else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element: any, index: number) => {
        if (typeof element != "object") {
          formData.append(`${formKey}[]`, element);
        } else {
          const tempFormKey = `${formKey}[${index}]`;
          convertModelToFormData(element, formData, tempFormKey);
        }
      });
    } else if (
      typeof model[propertyName] === "object" &&
      !(model[propertyName] instanceof File)
    ) {
      convertModelToFormData(model[propertyName], formData, formKey);
    } else {
      formData.append(formKey, model[propertyName].toString());
    }
  }
  return formData;
}
