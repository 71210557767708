
import { Component, Vue, Prop } from "vue-property-decorator";
import { API_HOST } from "../../global";

@Component({
  name: "Documents"
})
export default class Documents extends Vue {
  @Prop() compData!: any;
  HOST = API_HOST;
}
