export const LOGIN = "login";
export const GOOGLE_LOGIN = "googleLogin";
export const LOGOUT = "logout";
export const GET_PROFILE = "getProfile";
export const UPDATE_PROFILE = "uploadProfile";
export const GET_TOKEN_FROM_LOCAL_STORE = "getTokenFromLocalStore";
export const GET_COURSES = "getCourses";
export const CREATE_OR_EDIT_COURSE = "createOrEditCourse";
export const GET_COURSE_BY_SLUG = "getCourseBySlug";
export const GET_COURSE_MODULES = "getCourseModules";
export const GET_COURSE_CONTENTS = "getCourseContents";
export const GET_COURSE_CONTENT_BY_ID = "getCourseContentByID";
export const DELETE_COURSE_CONTENT = "deleteCourseContent";
export const ORDER_COURSE_CONTENT = "orderCourseContent";
export const CREATE_OR_EDIT_COURSE_MODULE = "createCourseModule";
export const EDIT_COURSE_MODULE = "editCourseModule";
export const ORDER_MODULES = "orderModules";
export const DELETE_MODULE = "deleteModule";
export const CREATE_OR_EDIT_COURSE_CONTENT = "createOrEditCourseContent";
export const GET_DEEP_CONTENTS = "getDeepContents";
export const CREATE_OR_EDIT_LESSON_CONTENT = "createOrEditLessonContent";
export const DELETE_DEEP_CONTENT = "deleteDeepContent";
export const CREATE_OR_EDIT_EXAM_CONTENT = "createOrEditExamContent";
export const CREATE_OR_EDIT_SLIDE_QUIZ = "createOrEditSlideQuiz"

export const FETCH_COURSES = "fetchCourses";
export const SEARCH_COURSES = "searchCourses";
export const FETCH_COURSE = "fetchCourse";
export const FETCH_COURSE_NEXT_CONTENT = "fetchCourseNextContent";
export const FETCH_ENROLLMENT = "fetchEnrollment";
export const FETCH_ENROLLMENTS = "fetchEnrollments";
export const FETCH_COMPLETED_ENROLLMENTS = "fetchCompletedEnrollments"
export const FETCH_COURSES_MODULES = "fetchCoursesModules";
export const FETCH_COURSES_META = "fetchCoursesMeta";
export const FETCH_EXAM_SHEET = "fetchExamSheet";
export const VALIDATE_EXAM_ANSWER = "validateExamAnswer";
export const FETCH_LESSON = "fetchLesson";
export const FETCH_LESSON_CONTENTS = "fetchLessonContents";
// Forum
export const CREATE_FORUM_CATEGORY = "createForumCategory";
export const FETCH_FORUM_CATEGORYS = "fetchForumCategorys";
export const FETCH_FORUM_CATEGORY = "fetchForumCategory";
export const CREATE_TOPIC = "createTopic";
export const FETCH_FORUM_TOPICS = "fetchForumTopics";
export const FETCH_FORUM_TOPIC = "fetchForumTopic";
export const CREATE_FORUM_TOPIC_THREAD = "createForumTopicThread";
export const FETCH_FORUM_TOPIC_THREAD = "fetchForumTopicThread";
export const FETCH_FORUM_MY_THREADS = "fetchForumMyThreads";
export const UPVOTE_THREAD = "upvoteThread"
export const DOWNVOTE_THREAD = "upvoteThread"
export const REPORT_TOPIC = "reportTopic"
export const BLOCK_TOPIC_USER = "blockTopicUser"
export const REPORT_THREAD = "reportThread"
export const BLOCK_THREAD_USER = "blockThreadUser"

// Peer Review Assignments
export const CREATE_OR_UPDATE_PEER_REVIEW_ASSIGNMENT = "createOrUpdatePeerReviewAssignment";
export const FETCH_PEER_REVIEW_ASSIGNMENT = "fetchPeerReviewAssignment";
export const DELETE_PEER_REVIEW_ASSIGNMENT = "deletePeerReviewAssignment";

// Peer Review User
export const FETCH_ENROLLMENT_PEER_REVIEW_ASSIGNMENTS = "fetchEnrollmentPeerReviewAssignments";
export const FETCH_ENROLLMENT_PEER_REVIEW_ASSIGNMENT = "fetchEnrollmentPeerReviewAssignment";
export const SUBMIT_PEER_REVIEW_ASSIGNMENT = "submitPeerReviewAssignment";

// Assignments
export const GET_ASSIGNMENTS = "getAssignments";
export const CREATE_ASSIGNMENTS = "createAssignments";
export const ADD_USERS_TO_ASSIGNMENT = "addUserToAssignment";
export const ADD_STATUS = "addStatus";
export const GET_ASSIGNEES = "getAssignees";
export const GET_ASSIGNEES_PROGRESS = "getAssigneesProgress";

// Media
export const GET_MEDIA_ITEMS = "getMediaContent";
export const DELETE_MEDIA_ITEM = "deleteMediaItem";
export const UPLOAD_MEDIA = "uploadMedia";
export const GET_MEDIA_ITEM_BY_ID = "getMediaItemByID";
export const CREATE_CERTIFICATE = `createCertificate`;
export const UPDATE_CERTIFICATE = "updateCertificate";

// Admin:User Management
export const GET_USERS = "getUsers";
export const UPDATE_USER = "updateUser";
export const FETCH_NOTIFICATIONS = "fetchNotifications";
export const RE_ENROLL = "reEnroll";
export const SET_PASSWORD = "setPassword";

// Admin: Organization Management
export const GET_ORGS = "getOrgs";
export const GET_ORG_BY_SLUG = "getOrgBySlug";
export const DELETE_ORG_MEMBER = "deleteOrgMember";
export const ADD_ORG_MEMBERS = "addOrgMembers";
export const CREATE_OR_UPDATE_ORGS = "createOrUpdateOrgs";

// ADMIN USER INVITE
export const INVITE_PEOPLE = "invitePeople";

export const UPDATE_COURSE_PUBLICATION = "updateCoursePublication";

export const GET_CERTIFICATE_THEME = "getCertificateTheme";
export const GET_DRAFTED_COURSE = "getDraftCourse";
export const FETCH_CERTIFICATE_BY_ID = "fetchCertificateByID";
export const FETCH_GENERAL_SETTINGS = "fetchGeneralSettings";
export const FETCH_GENERATE_CERTIFICATE = "fetchGenerateCertificate";
export const GET_CERTIFICATE_DETAIL = "getCertificateDetail";
export const SEND_PASSWORD_RESET = "getResetPassword";
export const PASSWORD_RESET_CONFIRM = "getResetPasswordConfirm";

export const FETCH_CMS_MAIN_MENU = "fetchCMSMainMenu";
export const FETCH_CURRENT_PAGE: string = "fetchCurrentPage";
export const FETCH_PAGE_BY_ID = "fetchPageByID";
export const FETCH_CHILDREN = "fetchChildren";

export const UPDATE_DONE_TUTORIAL: string = "updateDoneTutorial";

export const DELETE_USER: string = "deleteUser";
export const DISABLE_ENABLE_USER: string = "disableEnableUser";

export const SEND_EMAIL_USER: string = "sendEmailUser";

export const USER_SEARCH: string = "userSearch";

export const FETCH_PRIVACY_POLICY_PAGE: string = "fetchPrivacyPolicyPage";

export const FETCH_COURSE_MODULES: string = "fetchCourseModules";
export const GET_COURSE_CONTENT_SUMMERY: string = "getCourseContentSummery";
export const GET_COURSE_CONT: string = "getCourseCont";

export const ADMIN_COURSE_DELETE: string = "adminCourseDelete";

export const ADMIN_COURSE_MODULE_FETCH: string = "adminCourseModuleFetch";
export const ADMIN_ASSIGNEES_DELETE: string = "adminAssigneesDelete";

export const ADMIN_MODULE_STATUS_CHANGE: string = "adminModuleStatusChange"

export const ADMIN_MEDIA_CONTENT_FOLDER_CREATE: string = "adminMediaContentFolderCreate"
export const ALL_FOLDER: string = "allFolder"
export const DELETE_FOLDER: string = "deleteFolder"

export const FETCH_TOP_20: string = "fetchTop20";
export const COURSE_PARTICIPENTS: string = "courseParticipents";
export const SEARCH_PARTICIPENTS: string = "searchParticipents";

export const FETCH_COURSE_ADMIN: string = "fetchCourse";

export const FETCH_OVERALL_STATS: string = "fetchOverallStats";
export const FETCH_COURSE_STATS: string = "fetchCourseStats";
export const FETCH_DASHBOARD_STATS: string = "fetchDashboardStats";
export const FETCH_BY_COURSE_STATS: string = "fetchByCourseStats";

export const SEND_EMAIL_ALL_PARTICIPENTS: string = "sendEmailAllParticipents";

export const ASSIGN_BULK_UPLOAD: string = "assignBulkUpload";

export const EDUCATION_UPDATE: string = "educationUpdate";
export const EDUCATION_INFO_DELETE: string = "eduInfoDelete";
export const EMPLOYMENT_UPDATE: string = "employmentUpdate";
export const EMPLOYMENT_INFO_DELETE: string = "empInfoDelete";
export const CONTACT_UPDATE: string = "contactUpdate";

export const FETCH_USER = "fetchUSer";

// Game

export const FETCH_GAMES = "fetchGames";
export const FETCH_GAME = "fetchGame";
export const CREATE_OR_UPDATE_GAME = "createOrUpdateGame";

export const FETCH_GAME_STAGES = "fetchGameStages";
export const FETCH_GAME_STAGE = "fetchGameStage";
export const CREATE_OR_UPDATE_GAME_STAGE = "createOrUpdateGameStage";

export const FETCH_GAME_STAGE_OPTIONS = "fetchGameStageOptions";
export const FETCH_GAME_STAGE_OPTION = "fetchGameStageOption";
export const CREATE_OR_UPDATE_GAME_STAGE_OPTION = "createOrUpdateGameStageOption";

