import { RouteConfig } from "vue-router";

const forumRoutes: Array<RouteConfig> = [
    {
        name: "forum",
        path: "/forum",
        component: () => import("../views/ForumPage.vue"),
    },
    {
        name: "privacy-policy",
        path: "/privacy-policy",
        component: () => import("../views/PrivacyPolicyPage.vue"),
    },
    {
        name: "category-details",
        path: "/forum/:forumSlug",
        component: () => import("../views/ForumCategoryPage.vue"),
    },
    {
        name: "topic-details",
        path: "/forum/topic/:topicID",
        component: () => import("../views/ForumTopicPage.vue"),
    },
    {
        name: "forum-profile",
        path: "/forum-profile",
        component: () => import("../views/MyForumPostPage.vue"),
        meta: {
            loginRequred: true,
            accessLevel: 0,
        },
    },
];

export default forumRoutes;
