import { CMSState, RootState } from "../store.types";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";
import {
  FETCH_CURRENT_PAGE,
  FETCH_PAGE_BY_ID,
  FETCH_CHILDREN,
  FETCH_CMS_MAIN_MENU,
  FETCH_PRIVACY_POLICY_PAGE
} from "../action.names";
import {
  CMS_PAGE_PREVIEW_ENDPOINT,
  CMS_PAGE_ENDPOINT,
  CMS_MAIN_MENU_ENDPOINT,
} from "../endpoints";
import { buildParams } from "../../utils/api";
import axios from "axios";
import {
  SET_CURRENT_PAGE,
  SET_CURRENT_PAGE_ERROR,
  SET_CMS_MAIN_MENU,
} from "../mutation.names";
import router from "@/router";
import vue from "vue";
import { MAIN_MENU } from "../getter.names";
const namespaced: boolean = false;

const state: CMSState = {
  error: false,
  currentPage: null,
  mainMenu: [],
  mobileMenu: [],
};

const getters: GetterTree<CMSState, RootState> = {
  pageData(state): any | null {
    return state.currentPage;
  },
  [MAIN_MENU](state): any[] {
    return state.mainMenu;
  },
  // [MOBILE_MENU](state): any[] {
  //   return state.mobileMenu;
  // },
};

const actions: ActionTree<CMSState, RootState> = {
  async [FETCH_CURRENT_PAGE]({ commit }, to: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let url: string;
      let isPreview: boolean = false;
      if (to.query.content_type && to.query.token) {
        url = `${CMS_PAGE_PREVIEW_ENDPOINT}1/${buildParams(to.query)}`;
        isPreview = true;
      } else {
        const path = to.params.cmsPath
          ? to.params.cmsPath
          : router.currentRoute.params.cmsPath;
        url = `${CMS_PAGE_ENDPOINT}find/?html_path=/${path}`;
      }
      axios
        .get(url)
        .then(({ data }) => {
          if (isPreview) {
            commit(SET_CURRENT_PAGE, data);
            resolve(data);
          } else {
            axios
              .get(`${data.meta.detail_url}?fields=*`)
              .then(({ data }) => {
                commit(SET_CURRENT_PAGE, data);
                resolve(data);
              })
              .catch((err) => {
                commit(SET_CURRENT_PAGE_ERROR);
                reject(err);
              });
          }
        })
        .catch((err) => {
          commit(SET_CURRENT_PAGE_ERROR);
          reject(err);
        });
    });
  },
  async [FETCH_PRIVACY_POLICY_PAGE]({ commit }, to: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = `${CMS_PAGE_ENDPOINT}find/?html_path=/privacy-policy`;
      axios
        .get(url)
        .then(({ data }) => {
          axios
            .get(`${data.meta.detail_url}?fields=*`)
            .then(({ data }) => {
              commit(SET_CURRENT_PAGE, data);
              resolve(data);
            })
            .catch((err) => {
              commit(SET_CURRENT_PAGE_ERROR);
              reject(err);
            });
        })
        .catch((err) => {
          commit(SET_CURRENT_PAGE_ERROR);
          reject(err);
        });
    });
  },
  async [FETCH_PAGE_BY_ID]({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${CMS_PAGE_ENDPOINT}${payload.id}/${
            payload.params ? buildParams(payload.params) : ""
          }`
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [FETCH_CHILDREN]({ commit }, options: any): Promise<any> {
    // options = {
    //   id: 3,
    //   params: {
    //     type: "pages.ProjectPage"
    //   }
    // }
    return new Promise((resolve, reject) => {
      const params: any = {
        child_of: options.id,
        ...options.params,
      };
      axios
        .get(`${CMS_PAGE_ENDPOINT}${buildParams(params)}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [FETCH_CMS_MAIN_MENU]({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(CMS_MAIN_MENU_ENDPOINT)
        .then(({ data }) => {
          commit(SET_CMS_MAIN_MENU, data);
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  // async [FETCH_CMS_MOBILE_MENU]({ commit }): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(CMS_MOBILE_MENU_ENDPOINT)
  //       .then(({ data }) => {
  //         commit(SET_CMS_MOBILE_MENU, data);
  //         resolve(data);
  //       })
  //       .catch((e) => {
  //         reject(e);
  //       });
  //   });
  // },
};

const mutations: MutationTree<CMSState> = {
  [SET_CURRENT_PAGE](state, payload: any | null) {
    state.error = false;
    state.currentPage = payload;
  },
  [SET_CURRENT_PAGE_ERROR](state) {
    state.currentPage = null;
    state.error = true;
  },
  [SET_CMS_MAIN_MENU](state, data) {
    vue.set(state, "mainMenu", data);
  },
  // [SET_CMS_MOBILE_MENU](state, data) {
  //   vue.set(state, "mobileMenu", data);
  // },
};

export const pageData: Module<CMSState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
