export const dummyRoutes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      loginRequired: true,
      accessLevel: 0,
    },
  },
  {
    path: "/about",
    name: "AboutPage",
    component: () => import("../views/AboutPage.vue"),
  },
  {
    path: "/course-list",
    name: "CourseList",
    component: () => import("../views/CourseList.vue"),
  },
  {
    path: "/course-listgrid",
    name: "CourseListGridView",
    component: () => import("../views/CourseListGridView.vue"),
  },
  {
    path: "/image-lesson",
    name: "CourseImageLessonPage",
    component: () => import("../views/CourseImageLessonPage.vue"),
  },
  {
    path: "/leaderboard",
    name: "LeaderBoardPage",
    component: () => import("../views/LeaderBoardPage.vue"),
  },
  {
    path: "/course-start",
    name: "CourseStartPage",
    component: () => import("../views/CourseStartPage.vue"),
  },
  {
    path: "/video-lesson",
    name: "CourseVideoLessonPage",
    component: () => import("../views/CourseVideoLessonPage.vue"),
  },
  {
    path: "/pdf-lesson",
    name: "CoursePdfLessonPage",
    component: () => import("../views/CoursePdfLessonPage.vue"),
  },
  {
    path: "/text-lesson",
    name: "CourseTextLessonPage",
    component: () => import("../views/CourseTextLessonPage.vue"),
  },
  {
    path: "/mycourses",
    name: "MyCourses",
    component: () => import("../views/MyCourses.vue"),
  },

  {
    path: "/about-course",
    name: "CourseAbout",
    component: () => import("../views/CourseAbout.vue"),
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: () => import("../views/Faqs.vue"),
  },
  {
    path: "/course-evaluation",
    name: "CourseEvalution",
    component: () => import("../views/CourseEvalution.vue"),
  },

  {
    path: "/course-certificate",
    name: "CourseCertificatePage",
    component: () => import("../views/CourseCertificatePage.vue"),
  },
  {
    path: "/empty-profile",
    name: "EmptyProfilePage",
    component: () => import("../views/EmptyProfilePage.vue"),
  },
  {
    path: "/course-exam",
    name: "CourseExamPage",
    component: () => import("../views/CourseExamPage.vue"),
  },
  {
    path: "/course-exam-correction",
    name: "CourseExamCorrectionPage",
    component: () => import("../views/CourseExamCorrectionPage.vue"),
  },
  {
    path: "/lesson-text",
    name: "LessonTextPage",
    component: () => import("../views/LessonTextPage.vue"),
  },

  {
    path: "/rate-review",
    name: "CourseReviewPage",
    component: () => import("../views/CourseReviewPage.vue"),
  },
  // {
  //   path: "/forum",
  //   name: "ForumPage",
  //   component: () => import("../views/ForumPage.vue"),
  //   meta: {
  //     loginRequred: false,
  //     accessLevel: 0,
  //   },
  // },
  // {
  //   path: "/forum-topics",
  //   name: "ForumTopicPage",
  //   component: () => import("../views/ForumTopicPage.vue"),
  // },
  // {
  //   path: "/forum-profile",
  //   name: "ForumProfilePage",
  //   component: () => import("../views/ForumProfilePage.vue"),
  // },
  // {
  //   path: "/forum-post",
  //   name: "MyForumPostPage",
  //   component: () => import("../views/MyForumPostPage.vue"),
  // },
  //admin

  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // },
];

export default dummyRoutes;
