
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { TOKEN } from "./store/getter.names";
import { namespaced } from "./store/utils";
import { NS_USER } from "./store/namespace.names";
import { FETCH_CMS_MAIN_MENU, LOGOUT } from "./store/action.names";
import { Action } from "vuex-class";

import Navbar from "./components/Navbar.vue";
@Component({
  name: "App",
  components: {
    Navbar,
  },
})
export default class App extends Vue {
  @Getter(namespaced(NS_USER, TOKEN)) token: any;
  @Action(FETCH_CMS_MAIN_MENU) fetchCMSMainMenu: any;
  @Action(namespaced(NS_USER, LOGOUT)) logout: any;

  mounted() {
    this.fetchCMSMainMenu();
    document.addEventListener("visibilitychange", this.handleVisibility, false);
  }

  handleVisibility() {
    // if (document.visibilityState === 'hidden') {
    //   if(this.token){
    //     if(confirm("Changing tab logged you out!")){
    //       this.logout();
    //     }else{
    //       this.logout();
    //     }
    //   }else{
    //   }
    // } else {
    //   // tab visible
    // }
  }

  isIdle() {
    return this.$store.state.idleVue.isIdle;
  }

  // @Watch("$store.state.idleVue.isIdle")
  // changeIsIdle(val: any, oldVal: any){
  //   if(val === true){
  //     this.logout();
  //   }
  // }

  $router: any;
  @Watch("token")
  hadleTokenChange(val: null | string, oldVal: null | string) {
    if (val != oldVal) {
      if (val == null) {
        this.$router.push("/login");
      }
    }
  }

  get showNav(): boolean {
    return (this as any).$route.meta.accessLevel >= 0;
  }
}
