import { API_HOST } from "@/global";

export const API_ROOT = `${API_HOST}/api/v1`;
export const PUBLIC_API = `${API_ROOT}/public`;
export const ADMIN_API = `${API_ROOT}/admin`;

export const LOGIN_URL = `${API_ROOT}/auth/login`;
export const LOGOUT_URL = `${API_ROOT}/auth/logout`;
export const SET_PASSWORD_URL = `${PUBLIC_API}/user/invites/set-password`;

export const INVITATION_URL = `${PUBLIC_API}/user/invite`;
// .########..##.....##.########..##.......####..######.
// .##.....##.##.....##.##.....##.##........##..##....##
// .##.....##.##.....##.##.....##.##........##..##......
// .########..##.....##.########..##........##..##......
// .##........##.....##.##.....##.##........##..##......
// .##........##.....##.##.....##.##........##..##....##
// .##.........#######..########..########.####..######.

export const PROFILE_URL = `${PUBLIC_API}/profile`;

export const EDUCATION_INFO_UPDATE_URL = `${PUBLIC_API}/profile/education/`;
export const EMPLOYMENT_INFO_UPDATE_URL = `${PUBLIC_API}/profile/employment/`;
export const CONTACT_INFO_UPDATE_URL = `${PUBLIC_API}/contact/update/`;

export const COURSES_ENDPOINT = `${PUBLIC_API}/courses`;
export const PUBLIC_EXAM = `${PUBLIC_API}/exam`;
export const PUBLIC_LESSON = `${PUBLIC_API}/lesson`;
export const ENROLLMENT_ENDPOINT = `${PUBLIC_API}/enrollments`;
export const ADMIN_ENROLLMENT_ENDPOINT = `${ADMIN_API}/enrollments`;
export const NOTIFICATIONS_URL = `${PUBLIC_API}/notifications`;

export const GOOGLE_AUTHENTICATION_ENDPOINT: string = `${API_ROOT}/auth/google/`;

// ....###....########..##.....##.####.##....##
// ...##.##...##.....##.###...###..##..###...##
// ..##...##..##.....##.####.####..##..####..##
// .##.....##.##.....##.##.###.##..##..##.##.##
// .#########.##.....##.##.....##..##..##..####
// .##.....##.##.....##.##.....##..##..##...###
// .##.....##.########..##.....##.####.##....##

export const ADMIN_COURSES_URL = `${ADMIN_API}/courses`;
export const ADMIN_USERS_URL = `${ADMIN_API}/users`;
export const ADMIN_MEDIA_URL = `${ADMIN_API}/media-content`;
export const ADMIN_CERTIFICATE_URL = `${ADMIN_API}/certificate`;
export const ADMIN_INVITE_PEOPLE_URL = `${ADMIN_API}/invite-people`;
export const ADMIN_GET_CERTIFICATE_URL = `${ADMIN_API}/get-certificate`;
export const USER_ENABLE_DISABLE_URL = `${ADMIN_API}/user/deactivate`;
export const ASSIGN_BULK_UPLOAD_URL = `${ADMIN_API}/assign-bulk-upload`;
export const ADMIN_ORG_URL = `${ADMIN_API}/organizations`;
export const ADMIN_SLIDE_QUIZ_URL = `${ADMIN_API}/slide-quizzes`;

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: F O R U M   U R L : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export const FORUM_API = `${PUBLIC_API}/forum`;
export const FORUM_CATEGORY_API = `${FORUM_API}/categories`;
export const FORUM_TOPIC_API = `${FORUM_API}/topics`;
export const FORUM_MY_THREAD_API = `${FORUM_API}/thread/list`;
export const ADMIN_ASSIGNMENTS_URL = `${ADMIN_API}/assignments`;
export const ADMIN_ASSIGNEES_URL = `${ADMIN_API}/assignees`;
export const ADMIN_ASSIGNEES_LIST_URL = `${ADMIN_API}/assignees-list`;
export const ADMIN_PARTICIPENTS_URL = `${ADMIN_API}/assignee`;

//CERTIFICATE
export const CERTIFICATE_ENDPOINT = `${PUBLIC_API}/certificate-id/`;
export const GEN_CERTIFICATE_ENDPOINT = `${API_ROOT}/certificates/`;
export const GEN_RESET_PASSWORD_ENDPOINT = `${PUBLIC_API}/password/reset/`;

export const CMS_API_URL = `${API_ROOT}/cms`;
export const CMS_MENU_API_URL = `${API_ROOT}/menus`;
export const CMS_PAGE_ENDPOINT: string = `${CMS_API_URL}/pages/`;
export const CMS_PAGE_PREVIEW_ENDPOINT: string = `${CMS_API_URL}/page_preview/`;
export const CMS_MAIN_MENU_ENDPOINT: string = `${CMS_MENU_API_URL}/menu/`;

export const ADMIN_COURSE_MODULE_ENDPOINT: string = `${ADMIN_API}/course-timeline`;
export const ADMIN_ASSIGNEES_DELETE_ENDPOINT: string = `${ADMIN_API}/assignees`;

export const ADMIN_COURSE_MODULE_STATUS_CHANGE_ENDPOINT: string = `${ADMIN_API}/courses`;

export const ADMIN_MEDIA_CONTENT_FOLDER_ENDPOINT: string = `${ADMIN_API}/media-folder`;

export const ADMIN_COURSE_FETCH_ENDPOINT: string = `${ADMIN_API}/courses/`;

export const OVERALL_STATS_ENDPOINT: string = `${ADMIN_API}/overall-stats/`;
export const COURSE_STATS_ENDPOINT: string = `${ADMIN_API}/course-stats/`;
export const DASHBOARD_COURSES_ENDPOINT: string = `${ADMIN_API}/dashboard/courses/`;
