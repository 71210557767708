
import { Vue, Prop, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "RichText",
})
export default class RichText extends Vue {
  @Prop() compData: any;
}
