export const PROFILE = "user";
export const TOKEN = "authToken";
export const AUTH_HEADER = "authHeader";
export const ACCESS_LEVEL = "accessLevel";

export const COURSES = "courses";
export const ORGANIZATIONS = "organizations";
export const GET_COURSE_BY_SLUG = "getCourseBySlug";
export const ENROLLMENTS = "enrollments";
export const ENROLLMENT = "enrollment";
export const COURSE_MODULES = "courseModules";
export const COURSE_SEARCH_RESULTS = "courseSearchResults";
export const SITE_LANG = "siteLang";
export const COMPLETED_ENROLLMENTS = "completedEnrollments"

export const MAIN_MENU: string = "mainMenu";
export const PAGE_DATA: string = "pageData";

export const IS_TUTORIAL_DONE: string = "isTutorialDone";