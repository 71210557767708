import { AdminAssignmentState, RootState } from "../../store.types";
import { GetterTree, ActionTree, MutationTree, Module } from "vuex";
import {
  CREATE_ASSIGNMENTS,
  ADD_USERS_TO_ASSIGNMENT,
  ASSIGN_BULK_UPLOAD,
  FETCH_ENROLLMENTS
} from "../../action.names";
import { 
  ADMIN_ASSIGNMENTS_URL, 
  ADMIN_ASSIGNEES_URL, 
  INVITATION_URL, 
  ADMIN_ASSIGNEES_DELETE_ENDPOINT, 
  ADMIN_COURSE_FETCH_ENDPOINT, 
  ADMIN_ASSIGNEES_LIST_URL,
  ASSIGN_BULK_UPLOAD_URL,
  ADMIN_ENROLLMENT_ENDPOINT
} from "@/store/endpoints";
import { namespaced, buildParams } from "../../utils";
import { AUTH_HEADER, PROFILE } from "../../getter.names";
import { NS_USER } from "../../namespace.names";
import Axios from "axios";
import { GET_ASSIGNEES, GET_ASSIGNEES_PROGRESS, ADMIN_ASSIGNEES_DELETE, FETCH_COURSE_ADMIN } from "../../action.names";

const state: AdminAssignmentState = {};

const getters: GetterTree<AdminAssignmentState, RootState> = {};

const actions: ActionTree<AdminAssignmentState, RootState> = {
  async [CREATE_ASSIGNMENTS]({ rootGetters }, formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ADMIN_ASSIGNMENTS_URL}/`, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ADD_USERS_TO_ASSIGNMENT]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${INVITATION_URL}/invite/`, //`${INVITATION_URL}/${data.id}/add_user/`,
        data.payload,
        {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
          },
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ADMIN_ASSIGNEES_DELETE]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${ADMIN_ASSIGNEES_DELETE_ENDPOINT}/delete_assignees/`,
        data,
        {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
          },
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_ASSIGNEES]({ rootGetters }, params): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_ASSIGNEES_LIST_URL}/${buildParams(params)}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_ASSIGNEES_PROGRESS]({ rootGetters }, assigneesID): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_ASSIGNEES_URL}/${assigneesID}/progress/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_COURSE_ADMIN]({ rootGetters }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(ADMIN_COURSE_FETCH_ENDPOINT, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ASSIGN_BULK_UPLOAD]({ rootGetters }, payload): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ASSIGN_BULK_UPLOAD_URL}/`, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_ENROLLMENTS]({ rootGetters }, params): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_ENROLLMENT_ENDPOINT}/${buildParams(params)}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          console.log(data)
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }
};

const mutations: MutationTree<AdminAssignmentState> = {};

export const AdminAssignmentModule: Module<AdminAssignmentState, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state,
};

export default AdminAssignmentModule;
