import { FETCH_GAME_STAGE, FETCH_GAME_STAGES, CREATE_OR_UPDATE_GAME_STAGE, FETCH_GAME_STAGE_OPTIONS, FETCH_GAME_STAGE_OPTION, CREATE_OR_UPDATE_GAME_STAGE_OPTION, CREATE_OR_EDIT_SLIDE_QUIZ, FETCH_PEER_REVIEW_ASSIGNMENT, CREATE_OR_UPDATE_PEER_REVIEW_ASSIGNMENT, DELETE_PEER_REVIEW_ASSIGNMENT } from './../../action.names';
import { AdminCourseDataState, RootState } from "../../store.types";
import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import {
  GET_COURSES,
  CREATE_OR_EDIT_COURSE,
  GET_COURSE_BY_SLUG,
  CREATE_FORUM_CATEGORY,
  DELETE_MEDIA_ITEM,
  GET_CERTIFICATE_THEME,
  UPDATE_CERTIFICATE,
  GET_DRAFTED_COURSE,
  EDIT_COURSE_MODULE,
  ADMIN_MEDIA_CONTENT_FOLDER_CREATE,
  ALL_FOLDER,
  DELETE_FOLDER,
  FETCH_TOP_20,
  FETCH_GAMES,
  FETCH_GAME,
  CREATE_OR_UPDATE_GAME
} from "../../action.names";
import Axios from "axios";
import {
  ADMIN_COURSES_URL,
  ADMIN_MEDIA_URL,
  ADMIN_CERTIFICATE_URL,
  ADMIN_GET_CERTIFICATE_URL,
  ADMIN_COURSE_MODULE_ENDPOINT,
  ADMIN_COURSE_MODULE_STATUS_CHANGE_ENDPOINT,
  ADMIN_MEDIA_CONTENT_FOLDER_ENDPOINT,
  ADMIN_SLIDE_QUIZ_URL
} from "@/store/endpoints";
import { namespaced, buildParams } from "../../utils";
import { NS_ADMIN, NS_USER } from "../../namespace.names";
import { AUTH_HEADER } from "../../getter.names";
import {
  DELETE_COURSE_CONTENT,
  UPLOAD_MEDIA,
  DELETE_DEEP_CONTENT,
  CREATE_CERTIFICATE,
  UPDATE_COURSE_PUBLICATION,
  ADMIN_COURSE_DELETE,
  ADMIN_MODULE_STATUS_CHANGE
} from "../../action.names";
import {
  GET_COURSE_CONTENTS,
  GET_DEEP_CONTENTS,
  ORDER_COURSE_CONTENT,
} from "../../action.names";
import {
  DELETE_MODULE,
  CREATE_OR_EDIT_COURSE_CONTENT,
  CREATE_OR_EDIT_LESSON_CONTENT,
} from "../../action.names";
import {
  GET_COURSE_MODULES,
  CREATE_OR_EDIT_COURSE_MODULE,
  ORDER_MODULES,
  FETCH_COURSE_MODULES,
  GET_COURSE_CONTENT_SUMMERY,
  GET_COURSE_CONT,
  ADMIN_COURSE_MODULE_FETCH
} from "../../action.names";

const state: AdminCourseDataState = {};

const getters: GetterTree<AdminCourseDataState, RootState> = {};

const actions: ActionTree<AdminCourseDataState, RootState> = {
  async [GET_COURSES]({ rootGetters }, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${buildParams(params)}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_COURSE_MODULES]({ rootGetters }, courseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSE_MODULE_ENDPOINT}/${courseId}/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ADMIN_MODULE_STATUS_CHANGE]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.patch(`${ADMIN_COURSE_MODULE_STATUS_CHANGE_ENDPOINT}/${payload.slug}/modules/${payload.module_id}/`, {is_active: payload.is_active}, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_DRAFTED_COURSE]({ rootGetters }, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/get_draft_course/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ADMIN_COURSE_DELETE]({ rootGetters }, slug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.delete(`${ADMIN_COURSES_URL}/${slug}/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [CREATE_OR_EDIT_COURSE]({ rootGetters }, formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = Axios.post;
      let url = `${ADMIN_COURSES_URL}/`;
      let id: number | null = formData.get("id");
      let slug: string | null = null;
      if (id != null) {
        formData.delete("id");
        slug = formData.get("slug");
        formData.delete("slug");
      }
      if (id != null) {
        url = `${url}${slug}/`;
        method = Axios.patch;
      }
      method(url, formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_COURSE_BY_SLUG]({ rootGetters }, slug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${slug}/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_COURSE_MODULES]({ rootGetters }, slug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${slug}/modules/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_COURSE_CONTENT_SUMMERY]({ rootGetters }, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${params.slug}/modules/${params.moduleId}/contents/${params.contentId}/contents/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ADMIN_COURSE_MODULE_FETCH]({ rootGetters }, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${params.slug}/modules/${params.moduleId}/contents-all/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_COURSE_CONT]({ rootGetters }, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${params.slug}/modules/${params.moduleId}/contents/${params.contentId}/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_COURSE_CONTENTS]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.id}/contents/`,
        {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
          },
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [CREATE_OR_EDIT_COURSE_MODULE](
    { rootGetters },
    data: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ADMIN_COURSES_URL}/${data.slug}/modules/`, data.formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [EDIT_COURSE_MODULE](
    { rootGetters },
    data: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.patch(`${ADMIN_COURSES_URL}/${data.slug}/modules/${data.moduleId}/`, data.formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [ORDER_MODULES]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${ADMIN_COURSES_URL}/${data.slug}/modules/order/`,
        data.payload,
        {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
          },
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [DELETE_MODULE]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.delete(`${ADMIN_COURSES_URL}/${data.slug}/modules/${data.id}/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [FETCH_PEER_REVIEW_ASSIGNMENT]({ rootGetters }, { module_id, course_slug }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${course_slug}/modules/${module_id}/assignment/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [CREATE_OR_UPDATE_PEER_REVIEW_ASSIGNMENT]({ rootGetters }, { module_id, course_slug, payload }): Promise<any> {
    let method = Axios.post;
    if (payload.id) {
      method = Axios.patch;
      delete payload.id;
    }
    return new Promise((resolve, reject) => {
      method(`${ADMIN_COURSES_URL}/${course_slug}/modules/${module_id}/assignment/`, payload, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e: any) => {
          reject(e)
        })
    });
  },

  // Delete peer review assignment
  async [DELETE_PEER_REVIEW_ASSIGNMENT]({ rootGetters }, { module_id, course_slug }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.delete(`${ADMIN_COURSES_URL}/${course_slug}/modules/${module_id}/assignment/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(() => {
          resolve(true)
        })
        
        .catch((e: any) => {
          reject(e)
        })
    });
  },

  async [CREATE_OR_EDIT_COURSE_CONTENT](
    { rootGetters },
    data: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = Axios.post;
      let url = `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/`;
      if (data.formData.content_object.id != undefined) {
        url = `${url}${data.formData.content_object.id}/`;
        delete data.formData.content_object.id;
        method = Axios.patch;
      }
      method(url, data.formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [CREATE_OR_EDIT_SLIDE_QUIZ](
    { rootGetters },
    data: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = Axios.post;
      let url = `${ADMIN_SLIDE_QUIZ_URL}/`;
      if (data.id != undefined) {
        url = `${url}${data.id}/`;
        delete data.id;
        method = Axios.patch;
      }
      method(url, data, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [ORDER_COURSE_CONTENT]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/order/`,
        data.payload,
        {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
          },
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [DELETE_COURSE_CONTENT]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.delete(
        `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/${data.id}/`,
        {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
          },
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [CREATE_OR_EDIT_LESSON_CONTENT](
    { rootGetters },
    data: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let method = Axios.post;
      let url = `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/${data.course_content}/contents/`;

      if (data.formData.id != undefined) {
        url = `${url}${data.formData.id}/`;
        delete data.formData.id;
        method = Axios.patch;
      }

      if (data.formData.content_object) {
        if (data.formData.content_object.content_id === null) {
          delete data.formData.content_object.content_id;
        }
      }
      method(url, data.formData, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_DEEP_CONTENTS]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/${data.course_content}/contents/`,
        {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
          },
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DELETE_DEEP_CONTENT]({ rootGetters }, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.delete(
        `${ADMIN_COURSES_URL}/${data.slug}/modules/${data.module}/contents/${data.course_content}/contents/${data.id}/`,
        {
          headers: {
            ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
          },
        }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DELETE_MEDIA_ITEM]({ rootGetters }, ID): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.delete(`${ADMIN_MEDIA_URL}/${ID}/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [CREATE_CERTIFICATE]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ADMIN_CERTIFICATE_URL}/`, payload, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [UPDATE_CERTIFICATE]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.patch(`${ADMIN_CERTIFICATE_URL}/${payload.certificate_id}/`, payload.data, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_CERTIFICATE_THEME]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_GET_CERTIFICATE_URL}/${payload}/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [UPDATE_COURSE_PUBLICATION]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ADMIN_COURSES_URL}/${payload.slug}/change_is_published/`, {"is_published": payload.is_published},
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [ADMIN_MEDIA_CONTENT_FOLDER_CREATE]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ADMIN_MEDIA_CONTENT_FOLDER_ENDPOINT}/`, payload,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },

  async [ALL_FOLDER]({ rootGetters }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_MEDIA_CONTENT_FOLDER_ENDPOINT}/`,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [DELETE_FOLDER]({ rootGetters }, folder_id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.delete(`${ADMIN_MEDIA_CONTENT_FOLDER_ENDPOINT}/${folder_id}`,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_TOP_20]({ rootGetters }, slug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${slug}/top_20/`,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FETCH_GAMES]({ rootGetters }, slug): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${slug}/games/`,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FETCH_GAME]({ rootGetters }, {slug, id}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${slug}/games/${id}`,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [CREATE_OR_UPDATE_GAME]({ rootGetters }, { slug, payload }): Promise<any> {
    let method = Axios.post;
    let id: number;
    if (payload.id) {
      id = payload.id;
      method = Axios.patch;
      delete payload.id;
    }

    return new Promise((resolve, reject) => {
      method(`${ADMIN_COURSES_URL}/${slug}/games/${id ? '' + id + '/' : ''}`,
      payload,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FETCH_GAME_STAGES]({ rootGetters }, {slug, id}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${slug}/games/${id}/stages`,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FETCH_GAME_STAGE]({ rootGetters }, {slug, game_id, id}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${slug}/games/${game_id}/stages/${id}`,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [CREATE_OR_UPDATE_GAME_STAGE]({ rootGetters }, { slug, game_id, payload }): Promise<any> {
    let method = Axios.post;
    let id: number;
    if (payload.id) {
      id = payload.id;
      method = Axios.patch;
      delete payload.id;
    }

    return new Promise((resolve, reject) => {
      method(`${ADMIN_COURSES_URL}/${slug}/games/${game_id}/stages/${id ? '' + id + '/' : ''}`,
      payload,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FETCH_GAME_STAGE_OPTIONS]({ rootGetters }, {slug, game_id, id}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${slug}/games/${game_id}/stages/${id}/options/`,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [FETCH_GAME_STAGE_OPTION]({ rootGetters }, {slug, game_id, stage_id, id}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_COURSES_URL}/${slug}/games/${game_id}/stages/${stage_id}/options/${id}/`,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  [CREATE_OR_UPDATE_GAME_STAGE_OPTION]({ rootGetters }, { slug, game_id, stage_id, payload }): Promise<any> {
    let method = Axios.post;
    let id: number;
    if (payload.id) {
      id = payload.id;
      method = Axios.patch;
      delete payload.id;
    }

    return new Promise((resolve, reject) => {
      method(`${ADMIN_COURSES_URL}/${slug}/games/${game_id}/stages/${stage_id}/options/${id ? '' + id + '/' : ''}`,
      payload,
        { headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], }, }
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
};

const mutations: MutationTree<AdminCourseDataState> = {};

const adminCourseData: Module<AdminCourseDataState, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state,
};

export default adminCourseData;
