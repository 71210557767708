import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import Axios from "axios";

import { ForumCategoryState, RootState, ForumCategory } from "../../store.types";
import {
  CREATE_FORUM_CATEGORY,
  FETCH_FORUM_CATEGORYS,
  FETCH_FORUM_CATEGORY,
  CREATE_TOPIC,
  FETCH_FORUM_TOPICS,
  FETCH_FORUM_TOPIC,
  CREATE_FORUM_TOPIC_THREAD,
  FETCH_FORUM_TOPIC_THREAD,
  FETCH_FORUM_MY_THREADS,
  BLOCK_THREAD_USER,
} from "../../action.names";
import { FORUM_CATEGORY_API, FORUM_TOPIC_API, FORUM_MY_THREAD_API } from "@/store/endpoints";
import { namespaced, buildParams } from "../../utils";
import { NS_USER } from "../../namespace.names";
import { AUTH_HEADER } from "../../getter.names";

const state: ForumCategoryState = {
  category: {
    data: [],
    count: null,
    next: null,
    previous: null,
  },
};

const getters: GetterTree<ForumCategoryState, RootState> = {};

const actions: ActionTree<ForumCategoryState, RootState> = {
  async [CREATE_FORUM_CATEGORY]({ rootGetters }, payload: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${FORUM_CATEGORY_API}/`, payload, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      }).then(({ data }) => {
        resolve(data);
      }).catch((e: any) => {
        reject(e);
      });
    });
  },
  async [FETCH_FORUM_CATEGORYS]({ rootGetters }, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${FORUM_CATEGORY_API}/${buildParams(params)}`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_FORUM_CATEGORY]({ rootGetters }, category: any = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${FORUM_CATEGORY_API}/${category}/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [CREATE_TOPIC]({ rootGetters }, payload: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${FORUM_TOPIC_API}/`, payload, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)] },
      }).then(({ data }) => {
        resolve(data);
      }).catch((e: any) => {
        reject(e);
      });
    });
  },
  async [FETCH_FORUM_TOPICS]({ rootGetters }, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${FORUM_TOPIC_API}/${buildParams(params)}`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_FORUM_TOPIC]({ rootGetters }, topicID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${FORUM_TOPIC_API}/${topicID}/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_FORUM_TOPIC_THREAD]({ rootGetters }, topicID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${FORUM_TOPIC_API}/${topicID}/threads/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [CREATE_FORUM_TOPIC_THREAD]({ rootGetters }, payload: any): Promise<any> {
    let topicID = payload.topic;
    return new Promise((resolve, reject) => {
      Axios.post(`${FORUM_TOPIC_API}/${topicID}/threads/`, payload, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [FETCH_FORUM_MY_THREADS]({ rootGetters }): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${FORUM_MY_THREAD_API}/`, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [BLOCK_THREAD_USER]({ rootGetters }, payload: any): Promise<any> {
    let topicID = payload.topicID;
    delete payload.topicID;
    return new Promise((resolve, reject) => {
      Axios.post(`${FORUM_TOPIC_API}/${topicID}/block_user/`, payload, {
        headers: { ...rootGetters[namespaced(NS_USER, AUTH_HEADER)], },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
};

const mutations: MutationTree<ForumCategoryState> = {};

const forumCategory: Module<ForumCategoryState, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state,
};

export default forumCategory;
