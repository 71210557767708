import Vue from "vue";
import VueRouter from "vue-router";
import dummyRoutes from "./dummy";
import userRoutes from "./user";
import courseRoutes from "./course";
import forumRoutes from "./forum";
import { adminRoutes } from "./admin";

Vue.use(VueRouter);

const routes = [
  ...userRoutes,
  ...adminRoutes,
  ...courseRoutes,
  ...forumRoutes,
  ...dummyRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
