import { AdminMediaState, RootState } from "../../store.types";
import { ActionTree, MutationTree, GetterTree, Module } from "vuex";
import {
  GET_MEDIA_ITEMS,
  UPLOAD_MEDIA,
  GET_MEDIA_ITEM_BY_ID,
} from "../../action.names";
import Axios from "axios";
import { ADMIN_MEDIA_URL } from "@/store/endpoints";
import { namespaced, buildParams } from "../../utils";
import { NS_USER } from "../../namespace.names";
import { AUTH_HEADER } from "../../getter.names";
const state: AdminMediaState = {};

const getters: GetterTree<AdminMediaState, RootState> = {};

const actions: ActionTree<AdminMediaState, RootState> = {
  async [GET_MEDIA_ITEMS]({ rootGetters }, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_MEDIA_URL}/${buildParams(params)}`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [GET_MEDIA_ITEM_BY_ID]({ rootGetters }, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${ADMIN_MEDIA_URL}/${id}/`, {
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
  async [UPLOAD_MEDIA]({ rootGetters }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.post(`${ADMIN_MEDIA_URL}/`, payload.formData, {
        onUploadProgress: payload.progressFunc,
        headers: {
          ...rootGetters[namespaced(NS_USER, AUTH_HEADER)],
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  },
};

const mutations: MutationTree<AdminMediaState> = {};

export const mediaDataModule: Module<AdminMediaState, RootState> = {
  namespaced: false,
  getters,
  actions,
  mutations,
  state,
};

export default mediaDataModule;
