export const SET_PROFILE_DATA = "setProfileData";
export const CLEAR_PROFILE_DATA = "clearProfileData";
export const SET_TOKEN = "setToken";
export const SET_TOKEN_ERROR = "setTokenError";
export const SET_TOKEN_FROM_LOCAL_STORE = "setTokenFromLocalStore";

export const SET_COURSES = "appendCourses";
export const SET_COURSE_BY_SLUG = "setCourseBySlug";
export const SET_ENROLLMENTS = "setEnrollments";
export const SET_COMPLETED_ENROLLMENTS = "setCompletedEnrollments"
export const SET_ENROLLMENT = "setEnrollment";
export const SET_COURSE_MODULES = "setCourseModules";
export const SET_COURSE_SEARCH_RESULTS = "setCourseSearchResults";
export const SET_SITE_LANG = "setSiteLang";
export const RETRIEVE_SITE_LANG = "retrieveSiteLang";

export const SET_CURRENT_PAGE: string = "setCurrentPage";
export const SET_CURRENT_PAGE_ERROR: string = "setCurrentPageError";
export const SET_CMS_MAIN_MENU: string = "setCMSMainMenu";

export const SET_ORGS = "setOrgs";
export const SET_ORGS_ERROR = "setOrgsError";