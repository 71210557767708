
import { Component, Vue, Prop } from "vue-property-decorator";
import RichText from "../../components/cms/RichText.vue";
import { pageData } from "../../store/modules/pageData";
import FAQ from "../../components/cms/FAQ.vue";
import { Getter } from "vuex-class";
import Documents from "../../components/cms/Documents.vue";
import BannerCenterText from "@/components/BannerCenterText.vue";
import Accordion from "../../components/cms/Accordion.vue";
import Footer from "@/components/Footer.vue";


@Component({
  name: "BasicPage",
  components: {
    rich_text: RichText,
    faq: Accordion,
    documents: Documents,
    BannerCenterText,
    Footer
  }
})
export default class BasicPage extends Vue {
  @Prop() pageData: any;
}
