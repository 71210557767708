
import { Component, Vue } from "vue-property-decorator";
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import { Action, Getter } from "vuex-class";
import { FETCH_CURRENT_PAGE } from "../../store/action.names";
import { PAGE_DATA } from "../../store/getter.names";
import BasicPage from "./BasicPage.vue";

@Component({
  name: "Page",
  components: {
    Navbar,
    Footer,
    BasicPage
  }
})
export default class Page extends Vue {
  @Action(FETCH_CURRENT_PAGE) fetchCurrentPage: any;
  @Getter(PAGE_DATA) pageData: any;

  get pageType(): string {
    if (this.pageData) {
      return this.pageData.meta.type.split(".")[1];
    }
    return "";
  }

  fetchPage(routeData: any) {
    this.fetchCurrentPage(routeData).then((data: any) => {
      document.title = `${data.title} | ELEARNING-HNPP`;
    });
  }

  beforeRouteEnter(to: any, from: any, next: any) {
    // console.log(to, from);
    // this.fetchPage(to);
    next((vm:any) => {
      vm.fetchPage(to);
    });
  }
}
