
import { Component, Prop, Vue } from "vue-property-decorator";

import { API_HOST } from "../global";

@Component({
  name: "Banner",
})
export default class Banner extends Vue {
  @Prop() private title!: string;
  @Prop() private imgdesc!: string;
  @Prop() course: any;
  HOST = API_HOST;
}
